<template>

    <section class="section">
        <div class="container has-text-left">

            <div class="content">
                <h1 class="title is-4">Kontakt</h1>
                <p>
                    Der schnellste Weg sich kostenfrei beraten zu lassen und die Hilfe zu erhalten ist ein Anruf. Rufen Sie an.
                    <br/><br/>
                    Telefon: <a href="tel:07045200277">07045 - 200277</a>

                    <br/><br/>
                    Sollten wir gerade nicht erreichbar sein, sprechen Sie auf den Anrufbeantworter. Wir rufen Sie schnellstmöglichst zurück.

                    <br/><br/>
                    E-Mail: <a href="mailto:belzer@familienpflege-belzer.de">belzer@familienpflege-belzer.de</a><br/>
                    Fax: 07045 40193
                    <br/><br/>
                    Hölderlinstraße 25<br/>
                    75038 Oberderdingen
                </p>
            </div>
        </div>
    </section>

</template>

<script>
    export default {
        name: 'kontakt',
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    ul {
        list-style-type: circle;
        padding-left: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    /*h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }*/
</style>
